<template>
  <div id="lom-events-monthly-list">
    <v-overlay :value="OverlayLoadingFlag">
      <div class="text-center">
        <p>
          Please wait, your Report is getting generated. Loading time may vary
          depending on the number of events you have uploaded.
        </p>
        <p></p>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </v-overlay>
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ YearName }} - {{ PageDescription }}</h4></v-card-subtitle
      >

      <v-card-text>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <!-- <p><span class="text-danger">*</span> indicates required field</p> -->
            </div>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              large
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
            >
              <v-icon dark> mdi-refresh </v-icon> &nbsp; Refresh
            </v-btn>
            <router-link to="/lom-events/create">
              <v-btn
                large
                color="#00a4ef"
                elevation="30"
                shaped
                class="font-size-h6 mr-3 my-3 white--text"
              >
                <v-icon dark> mdi-file-document-edit </v-icon> &nbsp; Create
                event
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
        <br /><br />
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-dialog
          eager
          v-model="previewRecordPrompt"
          v-if="previewRecordPrompt"
        >
          <pdf-document-file-preview
            :PageTitle="PreviewPageTitle"
            :PreviewButtonFlag="previewRecordPrompt"
            :Documentpath="DocumentFilePath"
            @hideDialog="hidePreviewRecordPrompt"
            v-if="previewRecordPrompt"
          ></pdf-document-file-preview>
        </v-dialog>
        <v-container
          class="py-0"
          v-if="tableData1.length == 0 && LoadingFlag == false"
        >
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>No records found.</h3>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <h3>{{ tableData1.length }} records found.</h3> -->
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-btn v-if="DeleteFlag == 1" color="error" class="mr-4" @click="deleteRecords">
                  Delete
                </v-btn> -->
              <p></p>
              <json-excel
                v-if="
                  tableData1.length > 0 &&
                  tableOptions1.JsonDownloadButtonFlag == true
                "
                :name="tableOptions1.ExcelFileName"
                :data="tableData1"
                :fields="tableOptions1.JsonExcelFields"
              >
                <v-btn color="info" class="mr-4"> Download </v-btn>
              </json-excel>
            </v-col>
            <p></p>
          </v-row>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  small
                  elevation="30"
                  shaped
                  tile
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelDownloadFlag == true && ExcelFileFlag == false"
                  @click.prevent="generateReportExcel"
                  :loading="ExcelLoadingFlag"
                  target="_blank"
                  color="#ff7f50"
                >
                  Generate Report
                </v-btn>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelFileName != '' && ExcelFileFlag == true"
                  :href="ExcelFileName"
                  target="_blank"
                  color="#2fb65a"
                >
                  <v-icon dark> mdi-download </v-icon> Download Report
                </v-btn>
              </v-col>
            </v-row>
            <p></p>
          </v-container>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field> -->
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
                :hide-default-footer="tableOptions1.HideDefaultFooter"
              >
                <template v-slot:item.MrfEndDateTxt="{ item }">
                  <v-chip
                    :color="item.MrfEndDateColor"
                    draggable
                    dark
                    >{{ item.MrfEndDateTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.MonthlyEventsFlag="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.MonthlyEventsFlag && item.Total > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-find
                      </v-icon> -->
                      <v-btn
                        v-if="item.MonthlyEventsFlag && item.Total > 0"
                        @click="editData(item, e)"
                        target="_blank"
                        color="primary"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-find </v-icon> Events
                      </v-btn>
                    </template>
                    <span> {{ item.MonthName }} Events </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.FileGenerateFlag="{ index, item }">
                  <v-tooltip
                    bottom
                    v-if="item.FileGenerateFlag && item.Total > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.MonthlyEventsFlag && item.Total > 0"
                        @click="generateReport(index, item)"
                        target="_blank"
                        color="red"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-pdf </v-icon> Generate
                      </v-btn>
                    </template>
                    <span> Generate {{ item.MonthName }} MRF </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.FilePreviewFlag="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.FilePreviewFlag && item.FilePath != ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.FilePath != '' && item.FilePreviewFlag == 1"
                        @click="previewData(item, e)"
                        target="_blank"
                        color="blue"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-search </v-icon> Preview
                      </v-btn>
                    </template>
                    <span> {{ item.MonthName }} MRF Preview </span>
                  </v-tooltip>
                  <br />
                  <small> {{ item.LastUpdatedAtTxt }} </small>
                </template>
                <template v-slot:item.FileDownloadFlag="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.FileDownloadFlag && item.FilePath != ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.FilePath != '' && item.FileDownloadFlag == 1"
                        :href="item.FilePath"
                        target="_blank"
                        color="#2fb65a"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-download </v-icon> Download
                      </v-btn>
                    </template>
                    <span> {{ item.MonthName }} MRF Download </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";
import PdfDocumentFilePreview from "../../../../components/PdfDocumentFilePreview.vue";

export default {
  mixins: [common],
  components: { PdfDocumentFilePreview },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,
      OverlayLoadingFlag: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      rows: [],

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      DownloadFlag: 0,
      ExcelFileName: "",
      excel_fields: {
        "Lom Id": "LomId",
        "Zone Id": "ZoneId",
        "Lom Name": "LomName",
        "President Name": "LomPresidentFullName",
        "LOM NAME": "LomName",
        "Mobile No": "LomPresidentMobileNo",
        "Email Id": "LomPresidentEmailid",
      },

      recordToEdit: "",
      record: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      editPageDescription: "Assign LOM President for JCI India",

      DocumentFilePath: "",
      PreviewPageTitle: "MRF File Preview",

      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      ZoneCode: "",
      ZoneName: "",

      LomCode: "",
      LomName: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.JciYearCode = tr.CurrentJciYearId;
        this.YearName = tr.CurrentJciYearName;
        this.searchForm();
      }
    },
  },
  methods: {
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/lom-events/list";
        var year = tr.YearCode;
        console.log({ year });
        var month = tr.MonthId;
        console.log({ month });
        var params = {
          year: year,
          month: month,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    resetForm() {
      this.$refs.form2.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    editAlert(tr) {
      console.log("editAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        this.record = tr;
        this.recordToEdit = LomId;
        this.editPageDescription =
          "Assign 2021 LOM President for " + tr.LomName;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to modifiy";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      var message = "";
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      if (n1 > 0) {
        var FilePath = tr.FilePath;
        console.log({ FilePath });
        var MonthName = tr.MonthName;
        console.log({ MonthName });
        if (FilePath != "") {
          this.previewRecordPrompt = true;
          this.DocumentFilePath = FilePath;
          this.PreviewPageTitle = MonthName + " MRF Preview";
        } else {
          message = "File should not be empty.";
          this.toast("error", message);
        }
      } else {
        message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");

      var ZoneName = this.$session.get("ZoneName");
      ZoneName = ZoneName == (null || undefined) ? "" : ZoneName;
      console.log("ZoneName=" + ZoneName);
      this.ZoneName = ZoneName;

      var ZoneCode = this.$session.get("ZoneId");
      ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      this.ZoneCode = ZoneCode;

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? "" : LomCode;
      console.log("LomCode=" + LomCode);
      this.LomCode = LomCode;

      var LomName = this.$session.get("LomName");
      LomName = LomName == (null || undefined) ? "" : LomName;
      console.log("LomName=" + LomName);
      this.LomName = LomName;

      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_events",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");

      var message = "";

      // var validate1 = this.$refs.form2.validate();
      // console.log("validate1=" + validate1);

      var YearCode = this.JciYearCode;
      console.log({ YearCode });

      var LomCode = this.LomCode;
      console.log({ LomCode });

      if (YearCode != "" && LomCode != "") {
        this.LoadingFlag = true;
        this.tableData1 = [];
        this.ExcelDownloadFlag = false;
        this.ExcelFileName = "";
        this.ExcelFileFlag = false;
        this.Modules = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/month-list";
        var upload = {
          UserInterface: 1,
          Year: YearCode,
          Lom: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            console.log({ records });

            if (flag == 1) {
              var options = records.TableOptions;
              console.log({ options });

              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableOptions1 = options;
              thisIns.ExcelDownloadFlag = options.ExcelDownloadButtonFlag;
              thisIns.tableData1 = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (LomCode == "") {
          message += "Lom should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    generateReport(index, tr) {
      console.log("generateReport is called");
      var message = "";
      console.log({ index });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      // n1 = 0;
      if (n1 > 0) {
        // var filepath = this.tableData1[index].FilePath;
        var filepath =
          "http://192.168.1.101/projects/jci_india_erp/jci_india_erp_public/lib/assets/export/mrf/2021/848/jci_months.csv";
        console.log({ filepath });
        this.tableData1[index].FilePath = "";
        this.tableData1[index].FileDownloadFlag = false;
        this.tableData1[index].FilePreviewFlag = false;
        this.tableData1[index].LastUpdatedAtTxt = "";
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/generate-monthly-report";
        var upload = {
          UserInterface: 1,
          Year: tr.YearCode,
          Month: tr.MonthId,
          Lom: tr.LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.OverlayLoadingFlag = false;
            thisIns.searchForm();

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableData1[index].FileDownloadFlag =
              records.FileDownloadFlag;
            thisIns.tableData1[index].FilePreviewFlag =
              records.FileDownloadFlag;
            thisIns.tableData1[index].FilePath = records.FilePath;

            if (flag == 1) {
              thisIns.tableData1[index].FileDownloadFlag =
                records.FileDownloadFlag;
              thisIns.tableData1[index].FilePreviewFlag =
                records.FileDownloadFlag;
              thisIns.tableData1[index].FilePath = records.FilePath;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        message = "Kindly select one month to generate report";
        this.toast("error", message);
      }
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Event Type <b> " + tr.OrganizationTypeName + "</b>";
        htmlTxt += "<br/> Portfolio <b> " + tr.PortfolioShortCode + "</b>";
        htmlTxt += "<br/> Event name <b> " + tr.JciEventName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr.JciEventId);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(EventId) {
      console.log("deleteData is called");

      console.log("EventId=" + EventId);

      if (EventId > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/destroy";
        var upload = {
          UserInterface: 1,
          EventId: EventId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            table = response.data.TableContent;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Event Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-events-monthly-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
}
</style>